import dateFormat from "dateformat";
import { StyleSheet, css } from 'aphrodite/no-important';

import { sizes } from '../bits/sharedStyles';

/* Data */
export const edu = [
	{
		"school": "Great Neck South Highschool",
		"degree": "High School Advance Regents",
		"start": "2003",
		"end": "2007",
		"extra": [
			{
				"club": "Anime Club",
				"position": "President"
			},
			{
				"club": "Winter Track & Field Team",
				"position": "Mid Distance"
			},
			{
				"club": "Exit 33 (Literary Magazine)",
				"position": "Art Editor"
			}
		],
		"show": false
	},
	{
		"school": "Stony Brook University",
		"degree": "Bachelors",
		"major": "Applied Mathematics, and Computer Science",
		"start": "Sep 2007",
		"end": "Dec 2010",
		"extra": [
			{
				"club": "Iriving Hall Council",
				"position": "Advertisement Designer"
			}
		],
		"show": true
	},
	{
		"school": "Stony Brook University",
		"degree": "Masters",
		"major": "Computer Science, research focus: HCI",
		"start": "Jan 2011",
		"end": "May 2012",
		"extra": [
			{
				"club": "Chapin Aparments Residents Association",
				"position": "President"
			},
			{
				"club": "Campus Residents",
				"position": "Resident Assistant"
			},
			{
				"club": "Gradute Student Association",
				"position": "Computer Science Department Senator"
			}
		],
		"show": true
	},
	{
		"school": "General Assembly",
		"degree": "Certificate",
		"major": "User Experience Design",
		"start": "Mar 2015",
		"end": "May 2015",
		"show": true
	},
	{
		"school": "Ironhack Barcelona",
		"degree": "Bootcamp",
		"major": "UX/UI Design",
		"start": "Jan 2018",
		"end": "March 2018",
		"show": true
	}
];

export const jobs = [
	{
		"company": "Motorola Solutions",
		"title": "IT Intern",
		"start": "2008-06-01",
		"end": "2008-08-31",
		"details": [
			"Consolidated and prepared project data to migrate into a new project management system.",
			"Aided end users to adapt to the new system. Resolved and troubleshoot problems with migration."
		],
		"tags": ["intern", "support"]
	},
	{
		"company": "Stony Brook University, WISE",
		"title": "Office Assistant",
		"start": "2008-12-15",
		"end": "2009-04-30",
		"details": [
			"Help with extra workload during the program's freshmen admissions process.",
			"Devised new tracking system for organizing application process"
		],
		"tags": ["sorting", "office management"]
	},
	{
		"company": "Stony Brook University, Physics Department",
		"title": "Physics Teaching Assistant",
		"start": "2009-01-01",
		"end": "2009-05-31",
		"details": [
			"Aid students in completing homework challenges and lab reports in the Physics Help Room."
		],
		"tags": ["teach", "physics", "algebra"]
	},
	{
		"company": "Stony Brook University, Residential Tutoring Center",
		"title": "Math Tutor",
		"start": "2009-03-15",
		"end": "2009-05-31",
		"details": [
			"Tutor students who seek assistance in studying for exams in algebra and calculus."
		],
		"tags": ["teach", "math", "algebra", "calculus"]
	},
	{
		"company": "Motorola  Solutions",
		"title": "IT Intern",
		"start": "2009-06-01",
		"end": "2009-08-31",
		"details": [
			"Generated reports using SQL queries and Crystal Reports. The reports generated help Resource Managers to correctly allocate resources (workers)."
		],
		"tags": ["SQL", "excel macros"]
	},
	{
		"company": "Stony Brook University, Residential Tutoring Center",
		"title": "Physics Tutor",
		"start": "2009-09-15",
		"end": "2010-05-31",
		"details": [
			"Tutor students who seek assistance in studying for Physics exams and completing their homework assignments"
		],
		"tags": ["teach", "physics"]
	},
	{
		"company": "Stony Brook University, WISE",
		"title": "Mentor",
		"start": "2009-09-01",
		"end": "2010-05-31",
		"details": [
			"Guide an group of give freshmen women in the WISE (Women in Science & Engineering) program adjust to University academically, emotionally, and socially."
		],
		"tags": ["mentor"]
	},
	{
		"company": "Brookhaven National Labs",
		"title": "Research Intern",
		"start": "2010-06-01",
		"end": "2010-08-31",
		"details": [
			"Composed code in C++ using the ROOT library to extract experiment data.",
			"Analyzed collected data to verify the proper alignment of the Cathode Strip Chamber in the ATLAS detector, one of the four detectors located at CERN's Large Hadron Collider."
		],
		"tags": ["physics", "C++", "ROOT", "CERN", "LHC", "ALTAS", "computational science"]
	},
	{
		"company": "Motorola  Solutions",
		"title": "Software Developer",
		"start": "2011-03-01",
		"end": "2011-08-31",
		"details": [
			"Created interactive surveys and LMS tools for IT Communications Team."
		],
		"tags": ["PHP", "jQuery", "HTML5"]
	},
	{
		"company": "Stony Brook University, Campus Residences",
		"title": "Resident Assistant",
		"start": "2011-07-01",
		"end": "2012-05-31",
		"details": [
			"Managed building issues that arise in graduate student dorms",
			"Assured the safety and mental well being of student residents"
		],
		"tags": ["community build", "customer support", "event coordination"]
	},
	{
		"company": "Stony Brook University, Technology and Society Department",
		"title": "Research Assistant",
		"start": "2011-09-01",
		"end": "2012-05-31",
		"details": [
			"Developed HTML5/Javascript game to survey visual perceptions.",
			"Designed graphical interface and optimized code for Intelligent Energy Choices, a simulation game demonstrating how energy usage, GDP, and environmental changes are correlated."
		],
		"tags": ["Action Script", "game design", "user interaction", "Flash", "HTML5", "<canvas>"]
	},
	{
		"company": "Stony Brook University, Neuroscience Department",
		"title": "Project Aide",
		"start": "2012-06-01",
		"end": "2012-08-31",
		"details": [
			"Empower neuroscientist to create experiments by making a framework for control flow design.",
			"Design and develop GUI for experiment simulation and execution. The simulation interface provides real-time feedback, in the form of graphs to show the subject's actions and triggered stimuli."
		],
		"tags": ["python", "wxPython", "matplotlib"]
	},
	{
		"company": "Northrop Grumman",
		"title": "Software Engineer",
		"start": "2012-09-01",
		"end": "2013-07-12",
		"details": [
			"Design and develop web application used to manage changes to US and NATO communication standards."
		],
		"tags": ["C#", "Javascript", "jQuery", "HTML5"]
	},
	{
		"company": "Bytemark, Inc",
		"title": "Lead Frontend Developer",
		"start": "2013-07-15",
		"end": "2014-10-31",
		"details": [
			"Research and develop a new visual ticket schema using HTML5 canvas and video and CSS3.",
			"Implemented design specifications for our web application that customers users to buy their transit tickets.",
			"Developed an integration tool that connected our Phabricator and Bitbucket workflows to track tasks and release cycles."
		],
		"tags": ["Java", "play framework", "Javascript", "HTML5", "<canvas>", "MySQL"]
	},
	{
		"company": "Peloton Cycle",
		"title": "Full Stack Developer",
		"start": "2014-11-05",
		"end": "2016-04-01",
		"details": [
			"Created an end-to-end solution for generating metrics images per ride so that users can share on social media.",
			"Developed features for home page and web application in Angular, and internal CMS, and API (Python)",
			"Investigated other spin cycles and boutique fitness studios in NYC."
		],
		"tags": ["python", "PostgreSQL", "Javascript", "Angular.js"]
	},
	{
		"company": "JW Player, Longtail Video",
		"title": "Android Software Engineer",
		"start": "2016-04-20",
		"end": "2016-10-31",
		"details": [
			"Created a functional prototype for the new mobile web player using Framer.",
			"Investigated and fixed device-specific issues the Android SDK introduced."
		],
		"tags": ["Java", "Android SDK", "Framer.js"]
	},
	{
		"company": "Techolutions / J.Crew",
		"title": "Sr. Frontend Developer (Consultant)",
		"start": "2017-07-01",
		"end": "2018-01-26",
		"details": [
			"Employed by Techolutions, contracted out to J.Crew",
			"Work on A/B tested features and improvements to their site to drive up conversion rates",
			"Create a proof of concepts to help the designer define specifications for immersive product detail pages.",
			"Migrate their BackboneJs website to ReactJS"
		],
		"tags": ["ReactJs", "ES6"]
	},
	{
		"company": "Dark Inc.",
		"title": "Designer & UX Engineer",
		"start": "2018-07-01",
		"end": "2020-07-01",
		"details": [
			"Identified problems our users faced by providing support while they tried to build APIs in Dark's editor.",
			"Found low effort/high impact changes that would improve product usability",
			"Designed features users will need to write code within the Dark editor.",
			"Scoped feature specifications to meet project timeline and engineering resources.",
			"Wrote detailed style guidelines and state charts for a feature so engineers know what is necessary to implement.",
			"Supported engineers implementing a feature, helped them with software architecture and CSS, or made the designs easier to implement within the timeframe.",
			"Reduced user experience and visual inconsistencies users experience in the editor.",
			"Implemented solutions and features in OCaml and ReasonML whenever engineering resources were low.",
			"Designed graphical assets for company needs. I.e., icons, logo, mascot, print material."
		]
	},
	{
		"company": "Stedi Inc",
		"title": "Frontend Engineer",
		"start": "2020-08-01",
		"end": "2020-12-31",
		"details": [
			"Designed features to help our clients access their order transactions & tracking.",
			"Implemented features in React, EmotionJs, and Typescript.",
			"Coordinated with Product team and backend teams to design, build, and ship."
		]
	},
	{
		"company": "Lynxtool",
		"title": "Designer & UX Engineer",
		"start": "2021-01-04",
		"end": "2022-03-30",
		"details": [
			"Created a design system for our node-editor.",
			"Designed visual representation of programming concepts so non-programmers can easily understand them.",
			"Implemented features in Typescript using <canvas> Context2d and Svelte."
		]
	},
	{
		"company": "EY Design Studio",
		"title": "Senior Creative Technologist",
		"start": "2022-07-18",
		"end": "NOW",
		"details": [			
			"Spearheaded the development of a greenfield application as one of the initial frontend developers, laying the foundation for a scalable, user-centric platform.",
			"Solved behavioral and visual challenges in UI components, enabling the design team to concentrate on the overarching vision.",
			"Designed and prototyped micro-interactions to give the product a spark of life.",
			"Managed and extended a design system to ensure consistency and reusability of custom UI components across the application.",
			"Architected a reusable framework to dynamically render complex forms for tracking core business workflows, saving the project months of development time.",
			"Trained junior engineers to elevate their software engineering skills, improving team capabilities and velocity.",
			"Supported user testing by observing and documenting interactions with the EY-developed solution, identifying user errors, bugs, and feedback. Created an affinity map to uncover patterns and trends, providing a strategic roadmap for prioritizing fixes and features."		
		]
	},
];


export const langs = [
	{ label: "C#" },
	{ label: "C++", sub: [{ label: "OpenGL" }] },
	{ label: "Elm" },
	{ label: "Java", sub: [{ label: "Android" }, { label: "Processing" }] },
	{
		label: "Javascript", sub: [
			{ label: "Angular" },
			{ label: "<canvas> Context2D" },
			{ label: "D3.js" },
			{ label: "React" },
			{ label: "Svelte" },
			{ label: "Three.js" },
			{ label: "Vue" },
			{ label: "WebGL" },
		]
	},
	{ label: "OCaml" },
	{ label: "Python" },
	{ label: "Swift" },
	{
		label: "Markup Languages", sub: [
			{
				label: "CSS", sub: [
					{ label: "LESS" },
					{ label: "SASS" },
					{ label: "EmotionJs" }
				]
			},
			{ label: "HTML" },
			{ label: "LaTEX" }
		]
	}
];

export const tools = [
	{
		label: "Design", sub: [
			{ label: "Adobe XD" },
			{ label: "Figma" },
			{ label: "Photoshop" },
			{ label: "Sketch" },
		]
	},
	{
		label: "Prototyping", sub: [
			{ label: "Framer" },
			{ label: "Principle" },
			{ label: "Origami" }
		]
	},
];

/* Helper functions */
const viewDate = (d) => {
	try { return dateFormat(new Date(d), 'mmm yyyy'); }
	catch (e) {
		console.error(e);
		return null;
	}
}

/* Styles */
const style = StyleSheet.create({
	header: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",

		"@media (min-width: 768px)": {
			flexDirection: "row",
			flexWrap: "nowrap",
			justifyContent: 'space-between',
		}
	},
	sectionTitle: {
		borderBottom: `1px solid var(--color-dark-accent)`,
		paddingBottom: `${sizes.pad}px`,
		textAlign: 'right',
		marginTop: `${sizes.sectionSpace}px`,
		color: 'var(--color-dark-accent)',
		':first-child': {
			marginTop: 0
		}
	},
	// Job Styles
	jobTitle: {
		color: 'var(--color-dark)',
		marginBottom: `${sizes.pad}px`,
	},
	jobTenure: {
		marginBottom: `${sizes.pad}px`,
		//color: 'var(--color-dark-grey)',
		fontSize: '0.9em'
	},
	jobCompany: {

	},
	jobDuration: {
		textAlign: "left",
		"@media (min-width: 768px)": {
			textAlign: "right"
		},
	},
	jobDescriptions: {
		// listStyle: "none",
		marginLeft: `${2 * sizes.pad}px`,
		marginBlockStart: 0,
		paddingInlineStart: 0
	},
	schoolTenure: {
		fontSize: '0.9em'
	},
	card: {
		marginBottom: `${sizes.sectionSpace}px`,
		"@media print": {
			paddingBottom: `${sizes.sectionSpace}px`,
		},
	},
	job: {
		"@media print": {
			":nth-of-type(n+10)": {
				display: "none"
			}
		}
	},
	skillType: {
		marginLeft: "-1.45rem"
	},
	skillCols: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",

		"@media (min-width: 768px)": {
			flexDirection: "row",
			flexWrap: "nowrap",
			justifyContent: "space-between"
		},
	},
	skillColumn: {
		"@media print": {
			":nth-child(1n) ul": {
				paddingLeft: "1.45em"
			},
		},
	},
	primaryColor: {
		color: 'var(--color-dark-primary) !important'
	},
	skillsCatList: {
		marginLeft: '0 !important'
	}
});

/* Components */

const EduCard = ({ degree }) => (
	<div className={css(style.card)}>
		{degree.major && <h4 className={css(style.jobTitle)}>{degree.major}</h4>}
		<p>{degree.school}</p>
		<div className={css(style.header, style.schoolTenure)}>
			<span>{degree.degree}</span>
			<span className={css(style.jobDuration)}>{degree.start} - {degree.end}</span>
		</div>
		{/* degree.extra &&
			<ul className={css(style.jobDescriptions)}>
				<u>Extracurricular</u><br />
				{degree.extra.map((e, i ) => <li key={i}>{e.position} at {e.club}</li>)} </ul>
*/}
	</div>
);

const JobCard = ({ job }) => {
	const endDate = job.end === 'NOW' ? 'NOW' : viewDate(job.end);
	const startDate = viewDate(job.start);

	return (<div className={css(style.card, style.job)}>
		<h4 className={css(style.jobTitle, style.primaryColor)}>{job.title}</h4>
		<div className={css(style.header, style.jobTenure)}>
			<span className={css(style.jobCompany)}>{job.company}</span>
			<span className={css(style.jobDuration)}>{startDate} - {endDate}</span>
		</div>
		<ul className={css(style.jobDescriptions)}>
			{job.details.map((descript, i) => (<li key={i}>{descript}</li>))}
		</ul>
	</div>)
};

const SkillView = ({ skill }) =>
(<li>{skill.label}
	{skill.sub && <ul className={css(style.skillsCatList)}>{skill.sub.map((ss, i) => <SkillView skill={ss} key={i} />)}</ul>}
</li>);

const DesignTools = () => (<ul className={css(style.skillsCatList)}>
	<h4 className={css(style.skillType)}>Software</h4>
	{tools.map((s, i) => <SkillView skill={s} key={i} />)}
</ul>);

const Languages = () => (<ul className={css(style.skillsCatList)}>
	<h4 className={css(style.skillType)}>Languages</h4>
	{langs.map((s, i) => <SkillView skill={s} key={i} />)}
</ul>)

/* Exportable sections */

export const WorkExperience = () => (<><h2 className={css(style.sectionTitle)}>Work Experience</h2>{jobs.reverse().map((j, i) => <JobCard job={j} key={i} />)}</>);

export const Education = () => (<><h2 className={css(style.sectionTitle)}>Education</h2>{edu.filter((d) => d.show).map(((d, i) => <EduCard degree={d} key={i} />))}</>);

export const Skills = () => (<>
	<h2 className={css(style.sectionTitle)}>Technical Skills</h2>
	<DesignTools />
	<Languages />
</>)