import Content from "../content/lynxIf";
import Layout from "../bits/Layout";

import '../bits/case.css'

const Page = () => {

    return (<Layout>
        <div style={{ backgroundColor: "var(--color-light)" }}><Content /></div>
        </Layout>);

}


export default Page;